import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/admin/layout"
import Modal from "../../components/common/modal"
import request, { getUserRole } from "../../request"

const keyMap = {
  region: ["所處縣市"],
  memberName: ["會員名稱(廠名)", "例:立青工業股份有限公司"],
  owner: ["負責人姓名", "例:陳立青"],
  homeAddress: ["通訊處地址", "例:114台北市內湖區內湖路一段28號"],
  homeTel: ["通訊處電話", "例:(02)29195566"],
  homeFax: ["通訊處傳真", "例:(02)29195567"],
  factoryAddress: ["工廠地址", "例:239新北市鶯歌區中正一路19號"],
  factoryTel: ["工廠電話", "例:(02)29195568"],
  factoryFax: ["工廠傳真", "例:(02)29195569"],
  factoryDetail: ["工廠設備", "例:AC設備能量1.0T"],
  products: ["經營產品", "例:常溫瀝青混凝土"],
  representative: ["會員代表", "例:陳立青"],
  unionTitle: ["公會職務", "例:會員代表"],
  companyTitle: ["公司職務", "例:總經理"],
  liaisonOfficeAddress: ["聯絡處地址", "例:114台北市內湖區內湖路一段123號"],
  liaisonOfficeTel: ["聯絡處電話", "例:(02)29195577"],
  liaisonOfficeFax: ["聯絡處傳真", "例:(02)29195578"],
  affiliateWith: ["關係企業", "例:立青營造股份有限公司"],
  joinedAt: ["入會日期", "例:73年8月"],
}

const regionOrder = {
  基隆市: 1,
  台北市: 2,
  新北市: 3,
  桃園市: 4,
  新竹市: 5,
  新竹縣: 6,
  苗栗縣: 7,
  台中市: 8,
  彰化縣: 9,
  南投縣: 10,
  雲林縣: 11,
  嘉義市: 12,
  嘉義縣: 13,
  台南市: 14,
  高雄市: 15,
  屏東縣: 16,
  宜蘭縣: 17,
  花蓮縣: 18,
  台東縣: 19,
  澎湖縣: 20,
  金門縣: 21,
  連江縣: 22,
}

const getFreshAssociator = () =>
  Object.keys(keyMap).reduce((values, key) => {
    if (key === "region") values[key] = "基隆市"
    else values[key] = ""
    return values
  }, {})

const getFilters = (value = true) =>
  Object.keys(regionOrder).reduce((filterMap, region) => {
    filterMap[region] = value
    return filterMap
  }, {})

export default class Associator extends React.Component {
  state = {
    associators: [],
    isNew: true,
    reveal: false,
    keyword: "",
    filters: getFilters(),
    associator: getFreshAssociator(),
  }

  filterToggled = true

  searchRef = React.createRef()

  onNew = () => {
    this.setState({
      associator: getFreshAssociator(),
      reveal: true,
      isNew: true,
    })
  }

  onEdit = associator => {
    this.setState({ associator: { ...associator }, reveal: true, isNew: false })
  }

  onDelete = associator => {
    request.deleteAssociator(associator.id).then(() =>
      this.setState({
        associators: this.state.associators.filter(a => a.id !== associator.id),
      })
    )
  }

  onSearch = () => {
    this.setState({ keyword: this.searchRef.current.value })
  }

  onClearSearch = () => {
    this.searchRef.current.value = ""
    this.setState({ keyword: "" })
  }

  onCancel = () => {
    this.setState({ reveal: false })
  }

  onConfirm = () => {
    const associator = { ...this.state.associator }
    const { id } = associator
    delete associator.id
    if (id) {
      request.putAssociator(id, associator).then(res => {
        this.setState({
          associators: this.state.associators.map(a => {
            if (a.id === id) return { ...a, ...res.data }
            else return { ...a }
          }),
          reveal: false,
        })
      })
    } else {
      request.postAssociator(associator).then(res => {
        this.setState({
          associators: [...this.state.associators, res.data],
          reveal: false,
        })
      })
    }
  }

  fetchData = () =>
    request
      .getAssociators()
      .then(res => this.setState({ associators: res.data }))

  componentDidMount() {
    const role = getUserRole()
    if (role === 0) return navigate("/admin/login")
    if (role !== 1) return navigate("/admin/announcement")

    this.fetchData()
  }

  render() {
    if (!getUserRole()) return null

    const {
      associators,
      associator,
      filters,
      reveal,
      isNew,
      keyword,
    } = this.state
    const keys = Object.keys(keyMap)
    const filteredAssociators = (keyword
      ? associators.filter(associator => {
          for (let key of keys) {
            if (associator[key] && associator[key].indexOf(keyword) !== -1)
              return true
          }
          return false
        })
      : associators
    ).filter(associator => filters[associator.region])

    const regionCounter = getFilters(0)
    associators.forEach(a => (regionCounter[a.region] += 1))

    return (
      <Layout>
        <div className="ad-associator">
          <div className="ad-common-controls">
            <button onClick={this.onNew}>新增會員</button>
            <button
              onClick={() => {
                this.filterToggled = !this.filterToggled
                if (this.filterToggled) {
                  this.setState({ filters: getFilters(true) })
                } else {
                  this.setState({ filters: getFilters(false) })
                }
              }}
            >
              {this.filterToggled ? "取消全選全部城市" : "全選全部城市"}
            </button>
          </div>
          <hr />
          <br />
          <div className="ad-associator__filters">
            {Object.keys(regionOrder).map(region => (
              <div className="ad-associator__filter" key={region}>
                <input
                  id={`chk-${region}`}
                  type="checkbox"
                  checked={filters[region]}
                  onChange={() => {
                    this.setState({
                      filters: { ...filters, [region]: !filters[region] },
                    })
                  }}
                />
                <label htmlFor={`chk-${region}`}>
                  {`${region} (${regionCounter[region]}名)`}
                </label>
              </div>
            ))}
          </div>
          <table className="ad-associator-table">
            <thead>
              <tr>
                <th colSpan="6">
                  <div className="ad-associator-search">
                    <input
                      type="text"
                      ref={this.searchRef}
                      placeholder="在此輸入想要查找的關鍵字"
                    />
                    <button onClick={this.onSearch}>搜尋</button>
                    <button onClick={this.onClearSearch}>清除</button>
                  </div>
                </th>
              </tr>
              <tr>
                <th>所處縣市</th>
                <th>會員名稱(廠名)</th>
                <th>負責人姓名</th>
                <th>會員代表</th>
                <th>公會職務</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {filteredAssociators.map(associator => (
                <tr key={associator.id} onClick={() => this.onEdit(associator)}>
                  <td>{associator.region}</td>
                  <td>{associator.memberName}</td>
                  <td>{associator.owner}</td>
                  <td>{associator.representative}</td>
                  <td>{associator.unionTitle}</td>
                  <td>
                    <button
                      onClick={e => {
                        e.stopPropagation() // don't trigger row click
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("確定刪除此會員?")) {
                          this.onDelete(associator)
                        }
                      }}
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="6">
                  共有<span className="bold">{filteredAssociators.length}</span>
                  會員
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <Modal
          reveal={reveal}
          size="lg"
          header={isNew ? "新增會員" : "編輯會員"}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          textConfirm={isNew ? "新增" : "更新"}
        >
          <form className="ad-associator-form">
            <div className="ad-associator-form__group">
              <div className="ad-associator-form__name">所處縣市</div>
              <select
                className="ad-associator-form__key"
                value={associator.region}
                onChange={e =>
                  this.setState({
                    associator: { ...associator, region: e.target.value },
                  })
                }
              >
                {Object.keys(regionOrder).map(region => (
                  <option value={region} key={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>
            {Object.entries(keyMap)
              .filter(([key, _]) => key !== "region")
              .map(([key, [name, placeholder]], index) => (
                <div className="ad-associator-form__group" key={index}>
                  <div className="ad-associator-form__name">{name}</div>
                  <input
                    type="text"
                    className="ad-associator-form__key"
                    placeholder={placeholder}
                    value={associator[key]}
                    onChange={e =>
                      this.setState({
                        associator: { ...associator, [key]: e.target.value },
                      })
                    }
                  />
                </div>
              ))}
          </form>
        </Modal>
      </Layout>
    )
  }
}
